<template>
  <v-navigation-drawer
    v-model="drawer"
    clipped
    app
    right
    temporary
  >
    <v-subheader>Zoom <sup class="grey--text text--lighten-1">&nbsp;beta</sup></v-subheader>
    <v-list class="sidebar-user-list">
      <v-list-item>
        <v-slider
          v-model="zoom"
          class="slider-zoom pa-0"
          append-icon="zoom_in"
          prepend-icon="zoom_out"
          step="0"
          :min=".8"
          max="1"
          hide-details
          @click:prepend="() => zoom = .8"
          @click:append="() => zoom = 1"
        />
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <!-- DARK MODE -->

    <v-list class="sidebar-user-list">
      <v-list-item>
        <v-switch
          v-model="darkMode"
          label="Dark mode"
        />
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <!-- TOKEN -->

    <v-list class="sidebar-user-list">
      <v-list-item>
        <v-btn
          color="primary"
          width="100%"
          rounded
          @click="$store.commit('SET_TOKEN', 'qwe')"
        >
          <v-icon class="pr-1">
            broken_image
          </v-icon>&nbsp;
          Break token
        </v-btn>
      </v-list-item>
    </v-list>

    <v-divider />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SidebarRight',
  computed: {
    zoom: {
      get() {
        return this.$store.state.layout.zoom
      },
      set(value) {
        this.$store.commit('SET_ZOOM', value)
      },
    },
    drawer: {
      get() {
        return this.$store.state.layout.sidebarRight
      },
      set(value) {
        this.$store.commit('SET_SIDEBAR_RIGHT', value)
      },
    },
    darkMode: {
      get() {
        return this.$store.state.layout.dark
      },
      set(val) {
        this.$store.commit('SET_DARK_MODE', val)
        this.$vuetify.theme.dark = val
      },
    },
  },
}
</script>
