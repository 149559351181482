/**
 * custom implementation of lodash's get function
 *
 * @param array
 * @param path
 * @returns {*}
 * @private
 */
import _get from './_get'

function _sortBy(array: any[], path: string) {
    array = JSON.parse(JSON.stringify(array))
    if (path) {
        return array.sort((a, b) => _get(a, path, a).localeCompare(_get(b, path, b)))
    } else {
        return array.sort((a, b) => a.localeCompare(b))
    }
}

export { _sortBy }
