import axios from 'axios'
import { brokerUiPromise } from '@/shared/utility/brokerUiPromise'
import { CategoryProperties } from '@/modules/categories/models/category';

export default {
    selectCategory(context, id: string) {
        context.commit('SELECT_CATEGORY', id)
    },

    fetchCategories(context) {
        const url = '[mercury-broker]/admin/categories'
        const promise = axios.get(url)

        promise.then(result => {
            context.commit('SET_CATEGORY', [
                ...result.data.data.categories,
            ])
        })

        return brokerUiPromise({
            promise,
        })
    },

    createCategory(context, category: CategoryProperties) {
        const url = '[mercury-broker]/admin/categories'
        const promise = axios.post(url,
            {
                'name': category.name,
                'description': category.description,
            },
        )

        return brokerUiPromise({
            promise,
            mutationName: 'CREATE_CATEGORY',
            snackbarThenText: 'Category created',
        })
    },

    updateCategory(context, category: CategoryProperties) {
        const url = '[mercury-broker]/admin/categories/{id}'.replace('{id}', category?.id)
        const promise = axios.put(url, category)

        return brokerUiPromise({
            promise,
            mutationName: 'UPDATE_CATEGORY',
            snackbarThenText: 'Category updated',
        })
    },

    deleteCategory(context, id: string) {
        const url = '[mercury-broker]/admin/categories/{id}'.replace('{id}', id)
        const promise = axios.delete(url)

        return brokerUiPromise({
            promise,
            mutationName: 'DELETE_CATEGORY',
            snackbarThenText: 'Category deleted',
            mutationData: id,
        })
    },
}


export class CategoriesService {
    private store: any;

    constructor(store) {
        this.store = store;
    }

    fetchCategories() {
        return this.store.dispatch('fetchCategories')
    }

    createCategory(category: CategoryProperties): Promise<CategoryProperties> {
        return this.store.dispatch('createCategory', category)
    }

    updateCategory(category: CategoryProperties): Promise<CategoryProperties> {
        return this.store.dispatch('updateCategory', category)
    }

    deleteCategory(id: string) {
        return this.store.dispatch('deleteCategory', id)
    }

    selectCategory(id: string) {
        return this.store.dispatch('selectCategory', id)
    }
}
