export default {
    getMercuryAppInstances(state: any) {
        return state.mercuryAppInstances.order.map((id: string) => state.mercuryAppInstances.data[id])
    },
    getSelectedMercuryAppInstance(state: any) {
        if (!state.mercuryAppInstances.selected) {
            state.mercuryAppInstances.selected = 'DEFAULT'
        }
        return state.mercuryAppInstances.data[state.mercuryAppInstances.selected]
    },
}
