





























































// @ts-nocheck

import { PortalTarget } from 'portal-vue';

export default {
  name: 'AppBar',
  components: {PortalTarget},
  data: () => {
    return {
      drawer: {
        type: Boolean,
        default: true,
      },
    }
  },
  methods: {
    logout() {
      this.$store.commit('LOG_OUT')
      this.$router.push('/login')
    },
  },
}
