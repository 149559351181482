import auth from "@/shared/store/actions/auth"
import layout from "@/shared/store/actions/layout"
import mercuryAppInstances from "@/shared/store/actions/mercuryAppInstances"
import mercuryAppInstanceGroups from "@/shared/store/actions/mercuryAppInstanceGroups"
import categories from "@/shared/store/actions/categories";
import navigations from "@/shared/store/actions/navigations";
import restrictions from "@/shared/store/actions/restrictions";

export default {
  ...auth,
  ...layout,
  ...mercuryAppInstances,
  ...mercuryAppInstanceGroups,
  ...categories,
  ...navigations,
  ...restrictions,
}
