<template>
  <v-app>
    <AppBar v-if="loggedIn " />
    <SidebarLeft v-if="loggedIn" />
    <SidebarRight v-if="loggedIn" />
    <v-main :class="!loggedIn || 'main'">
      <v-container
        v-if="route !== '/login'"
        grid-list-xl
        fluid
        class="content pa-5 "
      >
        <router-view
          :style="{zoom:layout.zoom}"
          class="pl-3"
        />
      </v-container>
      <Login v-if="route === '/login'"></Login>
    </v-main>
    <snackbar></snackbar>
  </v-app>
</template>

<script>
import Login from "@/views/Login"
import AppBar from "./shared/components/layout/AppBar"
import SidebarLeft from "./shared/components/layout/SidebarLeft"
import SidebarRight from './shared/components/layout/SidebarRight'
import { mapState } from 'vuex'
import Snackbar from "@/shared/components/layout/Snackbar"


export default {
  name: 'App',

  components: {
    Snackbar,
    SidebarLeft,
    AppBar,
    SidebarRight,
    Login,
  },

  computed: {
    ...mapState(['layout']),
    loggedIn() {
      return this.$store.getters.isLoggedIn
    },
    route() {
      return this.$route.path
    },
  },

  watch: {
    route() {
      this.checkLogin()
    },
  },

  mounted() {
    this.checkLogin()
    this.$vuetify.theme.dark = this.layout.dark
  },

  methods: {
    checkLogin() {
      if (!this.loggedIn && this.$route.path !== '/login') {
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style lang="scss">

html {
  background-color: rgb(250, 250, 250);
}

h1 {
  margin-bottom: 20px;
}

.navbar {
  color: #fff;
  background: #141E30; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #243B55, #141E30); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .v-input .v-input__control {
    min-height: 35px;
  }
}

.v-list__group__header--active .v-list__group__header__prepend-icon .v-icon {
  color: #fff !important;
}

.tooltip__content {
  background: #404040;
}

.card--hover:hover {
  box-shadow: 0 5px 5px -4px rgba(0, 0, 0, 0.1), 0 7px 11px 1px rgba(0, 0, 0, 0.07), 0 1px 2px 2px rgba(0, 0, 0, 0.06);
}

.lane-card {
  border-top: 5px solid #01579b;
}

.lane-card-secondary {
  border-top: 5px solid #3d93d6;
}

.main-progress {
  position: absolute;
  bottom: 0;
  left: 0;
}


.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.card .subheader {
  color: rgb(1, 58, 113);
}

.sortable-ghost {
  opacity: .5;
}

.pointer {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-application .kinky {
  color: #d86d00;
  font-family: 'Tangerine', cursive !important;
}
</style>
