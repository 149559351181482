<template>
  <v-container
    fluid
    fill-height
    class="login"
    :style="{'background-image': `url(/bg/${getRandomBackground}.svg)`}"
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex shrink>
        <v-layout
          align-center
          column
          justify-center
        >
          <v-flex>
            <h1 class="logo mb-1">
              Mercury Admin UI
            </h1>
            <v-divider
              dark
              class="mt-2 mb-2"
            />
          </v-flex>
        </v-layout>
        <v-card
          light
          class="elevation-12"
        >
          <v-app-bar
            dark
            color="primary"
          >
            <v-toolbar-title>Login</v-toolbar-title>
          </v-app-bar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                outlined
                prepend-icon="person"
                name="login"
                label="Login"
                type="text"
                @keyup.enter="$refs.pass.focus()"
              />
              <v-text-field
                id="password"
                ref="pass"
                v-model="password"
                outlined
                prepend-icon="lock"
                name="password"
                label="Password"
                type="password"
                @keyup.enter="login()"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :loading="loading"
              color="light-blue darken-4"
              dark
              @click="login()"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      username: null,
      password: null,
      loading: false,
      bgs: [
        'Colorful-Stingrays',
        'Hollowed-Boxes',
        'Large-Triangles',
        'Subtle-Prism',
        'Wavey-Fingerprint',
        'Pattern-Randomized',
        'Polka-Dots',
        'Repeating-Chevrons',
      ],
    }
  },

  computed: {
    getRandomBackground() {
      return this.bgs[Math.floor(Math.random() * this.bgs.length)]
    },
  },

  methods: {
    ...mapActions(['fetchToken', 'setUser', 'fetchUser']),
    login() {
      this.loading = true
      this.fetchToken({
        username: this.username,
        password: this.password,
      }).then(() => {
        this.fetchUser()
      }).finally(() => {
        this.loading = false
        this.$router.push('/')
      })
    },
  },

}
</script>

<style>
.login {
  background-position: center;
  background-repeat: repeat;
}

.logo {
  color: white;
  font-size: 100px;
  line-height: 100px;
  margin-bottom: 60px;
  text-shadow: 1px 1px 8px #666;
}

</style>
