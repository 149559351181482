import store from '../store/store'
import _get from './_get'

export interface IBrokerPromiseArguments {
  promise: Promise<any>,
  mutationName?: string | null;
  snackbarThenText?: string | null;
  mutationData?: any,
  mutationCallback?: (...args: any[]) => any,
  responseTransformer?: ((...args: any[]) => any) | null,
  disableErrorHandling?: boolean | undefined
}

export function brokerUiPromise(
  {
    promise,
    mutationName = null,
    snackbarThenText = null,
    mutationData,
    mutationCallback,
    responseTransformer = null,
    disableErrorHandling = false,
  }: IBrokerPromiseArguments) {
  return promise.then((result: any) => {
    const data = _get(result, 'data.data', _get(result, 'data', result))
    const transformed = responseTransformer ? responseTransformer(data) : data

    if (mutationName) {
      if (mutationData && mutationCallback) {
        console.log('choose either mutation data or callback, not both')
        return
      } else if (mutationData) {
        store.commit(mutationName, mutationData)
      } else if (mutationCallback) {
        store.commit(mutationName, mutationCallback(transformed))
      } else {
        store.commit(mutationName, transformed)
      }
    }
    if (snackbarThenText) {
      store.dispatch('showSnackbar', {text: snackbarThenText, icon:'check'})
    }
    return transformed
  }).catch((error: any) => {
    if (!disableErrorHandling) {
      console.log(error)
      store.dispatch('setBaseLoading', false)
      store.dispatch('showSnackbar', {icon:'sentiment_dissatisfied',text: 'Beep boop. Error.', type: 'warning'})
      return null
    }
  })
}
