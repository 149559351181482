export default [
  {
    text: 'Home',
    link: '/dashboard',
    icon: 'dashboard',
    header: 'General',
    decorator: 'divider',
    color: 'blue-grey',
    description: 'You\'re right here!',
  },
  {
    text: 'App Instances',
    title: 'App Instances',
    target: 'app-instances',
    link: '/app-instances',
    icon: 'landscape',
    color: 'light-blue',
    description: 'Manage Mercury app instances',
  },
  {
    text: 'Categories',
    title: 'Categories',
    target: 'categories',
    link: '/categories',
    icon: 'category',
    color: 'red',
    description: 'Manage Categories',
  },
  {
    text: 'Navigation',
    title: 'Navigation',
    target: 'navigation',
    link: '/navigation',
    icon: 'directions',
    color: 'green',
    description: 'Navigation',
  },
  {
    text: 'Restrictions',
    title: 'Restrictions',
    target: 'restrictions',
    link: '/restrictions',
    icon: 'warning',
    color: 'yellow',
    description: 'Restrictions',
  },
]
