// @ts-nocheck

import Vue from 'vue'
import App from './App.vue'
import getRouter from './router'
import store from './shared/store/store'
import '@/shared/utility/interceptors'
import { _sortBy } from '@/shared/utility/_sort'
import VueCompositionAPI from '@vue/composition-api'
import vuetify from '@/shared/plugins/vuetify';
import PortalVue from 'portal-vue'

// define globally available states etc
Vue.prototype.$setBaseLoading = value => store.commit('SET_BASE_LOADING', value)
Vue.prototype._sortBy = _sortBy

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.filter('pretty', function (value) {
    return JSON.stringify(value, null, 2)
})

Vue.use(VueCompositionAPI)
Vue.use(PortalVue)

const app = new Vue({
    router: getRouter(store),
    store,
    vuetify,
    render: h => h(App),
})

app.$mount('#app')


