import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import actions from "@/shared/store/actions/actions"
import mutations from "@/shared/store/mutations/mutations"
import getters from "@/shared/store/getters/getters"

Vue.use(Vuex)

// state management
// > add new required state entries here
// > keep as flat as possible
// > clear cookies to reset to default
// > when adding mutations/getters/actions, make sure to update
//   the corresponding file (e.g. getters/getters.js)

// https://vuex.vuejs.org/guide/

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    layout: {
      sidebarLeft: true,
      sidebarRight: false,
      dark: false,
      zoom: 1,
      snackbar: {
        text: null,
        type: null,
        icon: null,
        customIcon: null,
        show: false,
      },
    },
    auth: {
      token: null,
      refreshToken: null,
      user: null,
    },
    mercuryAppInstances: {
      data: {
        DEFAULT: {
          id: 'DEFAULT',
          description: '',
        },
      },
      order: [],
      selected: 'DEFAULT',
    },
    mercuryAppInstanceGroups: {
      data: {
        DEFAULT: {
          id: 'DEFAULT',
          instanceName: 'DEFAULT',
          groupId: 'DEFAULT',
          groupName: 'DEFAULT',
        },
      },
      order: [],
      selected: 'DEFAULT',
    },
    categories: {
      data: {},
      order: [],
      selected: '',
    },
    restrictions: {},
  },
  actions,
  mutations,
  getters,
})
