export default {
  showSnackbar(context, {text, timeout = 40000, type = 'success', icon = null, customIcon = null}) {
    context.commit('SHOW_SNACKBAR', {text, type, icon, customIcon})

    setTimeout(() => {
      context.dispatch('hideSnackbar')
    }, timeout)
  },
  hideSnackbar(context) {
    context.commit('HIDE_SNACKBAR')
  },
  setBaseLoading(context, value) {
    context.commit('SET_BASE_LOADING', value)
  },
}
