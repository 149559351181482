import axios from 'axios'
import { brokerUiPromise } from '@/shared/utility/brokerUiPromise'

export default {
  fetchNavigations(context, id) {
    const url = `[mercury-broker]/admin/navigations/environments/${id}`
    const promise = axios.get(url)

    return brokerUiPromise({
      promise,
    })
  },

  fetchNavigationsInfo(context) {
    const url = '[mercury-broker]/admin/navigations/info'
    const promise = axios.get(url)

    return brokerUiPromise({
      promise,
    })
  },

  createNavigation(context, navigation) {
    const url = '[mercury-broker]/admin/navigations'
    const promise = axios.post(url, navigation)

    return brokerUiPromise({
      promise,
      snackbarThenText: 'Navigation created',
    })
  },

  updateNavigation(context, navigation) {
    const url = '[mercury-broker]/admin/navigations'
    const promise = axios.put(url, navigation)

    return brokerUiPromise({
      promise,
      snackbarThenText: 'Navigation updated',
    })
  },

  deleteNavigation(context, id) {
    const url = `[mercury-broker]/admin/navigations/${id}`
    const promise = axios.delete(url)

    return brokerUiPromise({
      promise,
      snackbarThenText: 'Navigation deleted',
    })
  },
}

