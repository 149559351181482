export default {
  SET_TOKEN(state, data) {
    state.auth.token = data
  },
  SET_REFRESH_TOKEN(state, data) {
    state.auth.refreshToken = data
  },
  SET_USER(state, data) {
    state.auth.user = data
  },
  LOG_OUT(state) {
    state.auth.user = null
    state.auth.token = null
    state.auth.refreshToken = null
  },
}
