import axios from 'axios'
import { brokerUiPromise } from '@/shared/utility/brokerUiPromise'

export default {
  fetchRestrictions(context, id) {
    const url = `[mercury-broker]/admin/restrictions/categories/environment/${id}`
    const promise = axios.get(url)

    return brokerUiPromise({
      promise,
    })
  },
  updateRestriction(context, restriction) {
    const url = `[mercury-broker]/admin/restrictions/categories/${restriction.categoryId}/groups/${restriction.groupId}`
    const promise = axios.put(url, restriction)

    return brokerUiPromise({
      promise,
      snackbarThenText: 'Restriction updated',
    })
  },
  createRestriction(context, restriction) {
    const url = `[mercury-broker]/admin/restrictions/categories/`
    const promise = axios.post(url, restriction)

    return brokerUiPromise({
      promise,
      snackbarThenText: 'Restriction updated',
    })
  },

  upsertRestrictions(context, restrictions) {
    const url = `[mercury-broker]/admin/restrictions/categories/upsert`
    const promise = axios.post(url, restrictions)

    return brokerUiPromise({
      promise,
      snackbarThenText: 'Restrictions updated',
    })
  },

}

