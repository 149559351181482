export default {
  getMercuryAppInstanceGroups(state) {
    return state.mercuryAppInstanceGroups.order.map(id => state.mercuryAppInstanceGroups.data[id])
  },
  getSelectedMercuryAppInstanceGroup(state) {
    if (!state.mercuryAppInstanceGroups.selected) {
      state.mercuryAppInstanceGroups.selected = 'DEFAULT'
    }
    return state.mercuryAppInstanceGroups.data[state.mercuryAppInstanceGroups.selected]
  },
}
