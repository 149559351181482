export default {
  SELECT_CATEGORY(state, data) {
    state.categories.selected = data
  },
  SET_CATEGORY(state, data) {
    let categories = {}
    let order: string[] = []

    data.forEach(entry => {
      categories[entry.id] = entry
      order.push(entry.id)
    })

    state.categories = {
      ...state.categories,
      data: categories,
      order: order,
    }
  },
  CREATE_CATEGORY(state, data) {
    state.categories.data = {
      ...state.categories.data,
      [data.id]: data,
    }

    state.categories.order = [
      ...state.categories.order,
      data.id,
    ]
  },
  UPDATE_CATEGORY(state, data) {
    state.categories.data[data.id] = {
      ...data,
    }
  },
  DELETE_CATEGORY(state, data) {
    let copiedState = {
      ...state.categories.data,
    }

    delete copiedState[data]

    state.categories.data = copiedState
    state.categories.order = state.categories.order.filter(value => value !== data)
  },
}
