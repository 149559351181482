import axios from 'axios'
import store from '../store/store'

// Auth Interceptor
// automatic injection of DUSE token if applicable
axios.interceptors.request.use(config => {
    const url = new URL(config.url as string);

    if (
        !['cloud.derif.in', 'cloud.derifin.net'].includes(url.hostname) ||
        (
            ['cloud.derif.in', 'cloud.derifin.net'].includes(url.hostname) &&
            url.pathname.includes('/api/auth/token') &&
            !url.pathname.includes('/api/auth/token/me')
        )
    ) {
        if (process.env.NODE_ENV === 'development') {
            console.log('Using no authentication')
        }
        return config
    } else {
        config.headers = {
            ...config.headers,
            'Authorization': 'Bearer ' + store.state.auth.token,
            retry: true,
        }
        return config
    }
}, error => {
    return Promise.reject(error)
})

axios.interceptors.request.use(config => {
    config.url = config.url?.replace('[duse]', process.env.VUE_APP_API_DUSE)
    config.url = config.url?.replace('[mercury-broker]', process.env.VUE_APP_API_MERCURY_BROKER)
    config.url = config.url?.replace('[mercury-content]', process.env.VUE_APP_API_MERCURY_CONTENT)

    if (process.env.NODE_ENV === 'development') {
        console.log('%c[interceptor]' + '%c ' + config.method?.toUpperCase() + ' ' + config.url, 'background: #222; color: #0063b9', 'background: #222; color: green')
    }

    return config
}, error => {
    return Promise.reject(error)
})

// if token is expired, try again with refresh token

axios.interceptors.response.use(config => {
    return config
}, error => {
    if (error.response) {
        if (process.env.NODE_ENV === 'development') {
            console.log('%c[interceptor]' + '%c----ERROR----', 'color: #72a2ef; background-color: black', 'color: red')
        }

        console.log(error.config)
        if (error.config && error.config.headers.retry === true && [401, 403].includes(error.response.status)) {
            return store.dispatch('refreshToken').then(() => {
                if (store.state.auth.token) {
                    error.config.headers = {
                        ...error.config.headers,
                        retry: false,
                    }
                    return axios.request(error.config)
                }
            }, error => {
                console.log(error)
            })
        } else {
            return Promise.reject(error)
        }
    } else if (error.request) {
        console.log(error.request)
    } else {
        console.log(error.message)
    }
})
