<template>
  <v-snackbar
    v-model="layout.snackbar.show"
    :timeout="5000"
    bottom
    right
    :color="layout.snackbar.type"
    outlined
    rounded
  >
    <v-layout align-center>
      <v-flex shrink>
        <v-icon
          v-if="layout.snackbar.icon && !layout.snackbar.customIcon"
          :color="layout.snackbar.type"
          class="pr-2"
        >
          {{ layout.snackbar.icon }}
        </v-icon>
        <span
          v-if="layout.snackbar.customIcon"
          v-html="layout.snackbar.customIcon"
        />
      </v-flex>
      <v-flex>
        <span
          v-html="layout.snackbar.text"
        />
      </v-flex>
      <v-flex shrink>
        <v-btn
          class="float-right"
          icon
          text
          :color="layout.snackbar.type"
          @click.native="$store.commit('HIDE_SNACKBAR')"
        >
          <v-icon>highlight_off</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Snackbar',
  computed: {
    ...mapState({layout: 'layout'}),
  },
}
</script>
