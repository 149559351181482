import auth from "@/shared/store/mutations/auth"
import layout from "@/shared/store/mutations/layout"
import mercuryAppInstances from "@/shared/store/mutations/mercuryAppInstances"
import mercuryAppInstanceGroups from "@/shared/store/mutations/mercuryAppInstanceGroups"
import categories from "@/shared/store/mutations/categories"

export default {
  ...auth,
  ...layout,
  ...mercuryAppInstances,
  ...mercuryAppInstanceGroups,
  ...categories,
}
