import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ './modules/dashboard/Dashboard.vue'),
    },
    {
        path: '/categories',
        name: 'Categories',
        component: () => import('./modules/categories/Categories.vue'),
    },
    {
        path: '/app-instances',
        name: 'app-instances',
        component: () => import(/* webpackChunkName: "app-instances" */ './modules/app-instances/AppInstances.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ './views/Login.vue'),
    },
    {
        path: '/navigation',
        name: 'Navigation',
        component: () => import(/* webpackChunkName: "about" */ './modules/navigation/Navigation.vue'),
    },
    {
        path: '/restrictions',
        name: 'Restrictions',
        component: () => import(/* webpackChunkName: "about" */ './modules/restrictions/Restrictions.vue'),
    },
]

function getRouter(store) {
    const router = new VueRouter({
        routes,
    })

    // check it logged in before every reroute
    router.beforeEach((to, from, next) => {
        const isLoggedIn = store.getters.isLoggedIn

        if ((isLoggedIn && to.fullPath !== '/login') || (!isLoggedIn && to.fullPath === '/login')) {
            next()
        } else if ((isLoggedIn && to.fullPath === '/login')) {
            next({
                path: '/',
            })
        } else {
            next({
                path: '/login',
            })
        }
    })

    return router
}


export default getRouter
