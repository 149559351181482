import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify, {VExpansionPanels} from 'vuetify/lib'

Vue.use(Vuetify)

// Here be theming options. Change the colors to give your app a unique feel!

// CSS variable can be used, see
// https://vuetifyjs.com/en/customization/theme/#custom-properties

export default new Vuetify({
  components: {VExpansionPanels},
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#01579b',
        secondary: '#b0bec5',
        accent: '#00bfa5',
        focus: '#ffcc00',
        error: '#cb1d1d',
        success: '#4CAF50',
        warning: '#e78a05',
        nav: '#01579b',
        sidebarLeft: '#e0e0e0',
      },
      dark: {
        primary: '#01579b',
        accent: '#00bfa5',
        focus: '#ffcc00',
        error: '#cb1d1d',
        success: '#4CAF50',
        warning: '#FFC107',
        nav: '#01579b',
        sidebarLeft: '#212121',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
})
