import axios from 'axios'
import {brokerUiPromise} from '@/shared/utility/brokerUiPromise'

export default {
  selectMercuryAppInstanceGroup(context, {id}) {
    context.commit('SELECT_MERCURY_APP_INSTANCE_GROUP', id)
  },

  fetchMercuryAppInstanceGroups(context, activeAppInstance) {
    const environmentId = activeAppInstance.environmentId
    const url = '[mercury-broker]/admin/environments/{environmentId}/groups'
        .replace('{environmentId}', environmentId)
    const promise = axios.get(url)

    promise.then(result => {
      context.commit('SET_MERCURY_APP_INSTANCE_GROUP', [
        {
          id: 'DEFAULT',
          instanceName: 'DEFAULT',
          groupId: 'DEFAULT',
          groupName: 'DEFAULT',
        },
        ...result.data.data,
      ])
    })

    return brokerUiPromise({
      promise,
    })
  },

  createMercuryAppInstanceGroup(context, mercuryAppInstanceGroup) {
    console.log(mercuryAppInstanceGroup.groupId)
    const environmentId = mercuryAppInstanceGroup.instanceName
    const url = '[mercury-broker]/admin/environments/{instanceName}/groups'
        .replace('{instanceName}', environmentId)
    const promise = axios.post(url,
        {
        'name': mercuryAppInstanceGroup.groupId,
      },
    )

    return brokerUiPromise({
      promise,
      mutationName: 'CREATE_MERCURY_APP_INSTANCE_GROUP',
      snackbarThenText: 'Mercury app instance group saved',
    })
  },

  deleteMercuryAppInstanceGroup(context, mercuryAppInstanceGroup) {
    const environmentId = mercuryAppInstanceGroup.instanceName
    const mercuryAppInstanceGroupId = mercuryAppInstanceGroup.id
    const url = '[mercury-broker]/admin/environments/{environmentId}/groups/{mercuryAppInstanceGroupId}'
        .replace('{environmentId}', environmentId)
        .replace('{mercuryAppInstanceGroupId}', mercuryAppInstanceGroupId)
    const promise = axios.delete(url)

    return brokerUiPromise({
      promise,
      mutationName: 'DELETE_MERCURY_APP_INSTANCE_GROUP',
      snackbarThenText: 'Mercury app instance group deleted',
      mutationData: mercuryAppInstanceGroupId,
    })
  },
}
