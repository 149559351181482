import auth from "@/shared/store/getters/auth"
import mercuryAppInstances from "@/shared/store/getters/mercuryAppInstances"
import mercuryAppInstanceGroups from "@/shared/store/getters/mercuryAppInstanceGroups"
import categories from "@/shared/store/getters/categories";

export default  {
  ...auth,
  ...mercuryAppInstances,
  ...mercuryAppInstanceGroups,
  ...categories,
}
