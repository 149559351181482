import { CategoryProperties } from '@/modules/categories/models/category';

export default {
    getCategories(state): CategoryProperties[] {
        return state.categories.order.map(id => state.categories.data[id])
    },
    getSelectedCategory(state): CategoryProperties {
        return state.categories.data[state.categories.selected]
    },
}
