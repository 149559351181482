import { AppInstanceProperties } from '@/modules/app-instances/models/app-instance';

export default {
  SELECT_MERCURY_APP_INSTANCE(state, data) {
    state.mercuryAppInstances.selected = data
  },
  SET_MERCURY_APP_INSTANCE(state, data: AppInstanceProperties[]) {
    let mercuryAppInstances = {}
    let order: string[] = []

    data.forEach(entry => {
      mercuryAppInstances[entry.id] = entry
      order.push(entry.id)
    })

    state.mercuryAppInstances = {
      ...state.mercuryAppInstances,
      data: mercuryAppInstances,
      order: order,
    }
  },
  CREATE_MERCURY_APP_INSTANCE(state, data) {
    state.mercuryAppInstances.data = {
      ...state.mercuryAppInstances.data,
      [data.id]: data,
    }

    state.mercuryAppInstances.order = [
      ...state.mercuryAppInstances.order,
      data.id,
    ]
  },
  UPDATE_MERCURY_APP_INSTANCE(state, data) {
    state.mercuryAppInstances.data[data.id] = {
      ...data,
    }
  },
  DELETE_MERCURY_APP_INSTANCE(state, data) {
    let copiedState = {
      ...state.mercuryAppInstances.data,
    }

    delete copiedState[data]

    state.mercuryAppInstances.data = copiedState
    state.mercuryAppInstances.order = state.mercuryAppInstances.order.filter(value => value !== data)
  },
}
