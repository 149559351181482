import axios from 'axios'
import { brokerUiPromise } from '@/shared/utility/brokerUiPromise'
import { AppInstanceProperties } from '@/modules/app-instances/models/app-instance';



export default {
    selectMercuryAppInstance(context: any, {id}: AppInstanceProperties) {
        context.commit('SELECT_MERCURY_APP_INSTANCE', id)
    },

    fetchMercuryAppInstances(context: any): Promise<any> {
        const url = '[mercury-broker]/admin/app-instances'
        const promise = axios.get(url)

        promise.then(result => {
            context.commit('SET_MERCURY_APP_INSTANCE', [
                {
                    id: 'DEFAULT',
                    environmentId: 'DEFAULT',
                    description: '',
                },
                ...result.data.data.appInstances,
            ])
        })

        return brokerUiPromise({
            promise,
        })
    },

    createMercuryAppInstance(context: any, mercuryAppInstance: AppInstanceProperties) {
        const url = '[mercury-broker]/admin/app-instances'
        const promise = axios.post(url,
            {
                'environmentId': mercuryAppInstance.id,
                'description': mercuryAppInstance.description,
            },
        )

        return brokerUiPromise({
            promise,
            mutationName: 'CREATE_MERCURY_APP_INSTANCE',
            snackbarThenText: 'Mercury app instance created',
        })
    },

    updateMercuryAppInstance(context: any, mercuryAppInstance: AppInstanceProperties) {
        const mercuryAppInstanceId = mercuryAppInstance.id
        const url = '[mercury-broker]/admin/app-instances/{mercuryAppInstanceId}'
            .replace('{mercuryAppInstanceId}', mercuryAppInstanceId)
        const promise = axios.put(url, mercuryAppInstance)

        return brokerUiPromise({
            promise,
            mutationName: 'UPDATE_MERCURY_APP_INSTANCE',
            snackbarThenText: 'Mercury app instance updated',
        })
    },

    deleteMercuryAppInstance(context: any, mercuryAppInstanceId: string) {
        const promise = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    data: {
                        data: {
                            id: mercuryAppInstanceId,
                        },
                    },
                })
            }, 2500)
        })

        return brokerUiPromise({
            promise,
            mutationName: 'DELETE_MERCURY_APP_INSTANCE',
            snackbarThenText: 'Mercury app instance deleted',
            mutationData: mercuryAppInstanceId,
        })
    },
}
