export default {
  SELECT_MERCURY_APP_INSTANCE_GROUP(state, data) {
    state.mercuryAppInstanceGroups.selected = data
  },
  SET_MERCURY_APP_INSTANCE_GROUP(state, data) {
    let mercuryAppInstanceGroups = {}
    let order: string[] = []

    data.forEach(entry => {
      mercuryAppInstanceGroups[entry.id] = entry
      order.push(entry.id)
    })

    state.mercuryAppInstanceGroups = {
      ...state.mercuryAppInstanceGroups,
      data: mercuryAppInstanceGroups,
      order: order,
    }
  },
  CREATE_MERCURY_APP_INSTANCE_GROUP(state, data) {
    state.mercuryAppInstanceGroups.data = {
      ...state.mercuryAppInstanceGroups.data,
      [data.id]: data,
    }

    state.mercuryAppInstanceGroups.order = [
      ...state.mercuryAppInstanceGroups.order,
      data.id,
    ]
  },
  UPDATE_MERCURY_APP_INSTANCE_GROUP(state, data) {
    state.mercuryAppInstanceGroups.data[data.id] = {
      ...data,
    }
  },
  DELETE_MERCURY_APP_INSTANCE_GROUP(state, data) {
    let copiedState = {
      ...state.mercuryAppInstanceGroups.data,
    }

    delete copiedState[data]

    state.mercuryAppInstanceGroups.data = copiedState
    state.mercuryAppInstanceGroups.order = state.mercuryAppInstanceGroups.order.filter(value => value !== data)
  },
}
