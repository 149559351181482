import axios from 'axios'
import getRouter from '@/router'

export default {
  fetchToken(context, {username, password}) {
    const url = '[duse]/auth/token?includeRefreshToken=true'
    const promise = axios.get(url, {auth: {username, password}})

    promise.then(response => {
      context.commit('SET_TOKEN', response.data.token)
      context.commit('SET_REFRESH_TOKEN', response.data.refreshToken)
      getRouter(context).push({path: '/'})
    }).catch((err) => {
      console.log(err)
      context.dispatch('showSnackbar', {text: 'Sign in failed', type: 'error'})
    })

    return promise
  },
  refreshToken(context) {
    const url = `[duse]/auth/token/refresh?refreshtoken=${context.state.auth.refreshToken}`
    const promise = axios.get(url)

    return promise.then(response => {
      context.commit('SET_TOKEN', response.data.token)
    }, () => {
      context.dispatch('logout')
    })
  },
  fetchUser(context) {
    const promise = axios.get('[duse]/auth/token/me')

    return promise.then(response => {
      context.commit('SET_USER', response.data)
    }, () => {
      context.dispatch('logout')
      context.dispatch('showSnackbar', {text: 'Couldn\'t fetch user', type: 'error'})
    })
  },
  setUser(context, user) {
    context.commit('SET_USER', user)
  },
  logout(context) {
    context.commit('SET_TOKEN', null)
    context.commit('SET_REFRESH_TOKEN', null)
    context.commit('SET_USER', null)
    getRouter(context).push({path: '/login'})
  },
  breakToken(context) {
    context.commit('SET_TOKEN', '2iphbuei2bue2e')
    // context.dispatch('showSnackbar', {text: 'Broke the token. Ouch!', type: 'warning'})
  },
  breakRefreshToken(context) {
    context.commit('SET_REFRESH_TOKEN', '2iphbuei2bue2e')
    // context.dispatch('showSnackbar', {text: 'Broke the refresh token. Owiee!', type: 'warning'})
  },
}
