// @ts-nocheck
/**
 * custom implementation of lodash's get function
 *
 * @param object
 * @param path
 * @param defaultValue
 * @returns {*}
 * @private
 */

function _get(object, path, defaultValue) {
    switch (arguments.length) {
        case 0:
            console.log('_get outta here!!!')
            return null
        case 1:
            console.log('Only silly nillies use _get without a path 🙄')
            return object
        case 2:
            defaultValue = false
    }
    path = typeof path === 'string' ? path.split('.') : path
    if (path.length > 0) {
        if (
            object !== null && (
            (object.constructor === Object && Object.keys(object).includes(path[0])) ||
            (object.constructor === Array && object.length > path[0]))
        ) {
            return _get(object[path[0]], path.slice(1), defaultValue)
        } else {
            return defaultValue
        }
    } else {
        return object
    }
}

export default _get
